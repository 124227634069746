//CONSTANTS
var NO_FUNCTION_FOUND = 'ERROR: native listener not found';

//BRIDGE
var Bridge = {
  pingAuthenticationSuccess: function (authenticated, sessionCookie, impersonating, userType, firstName, lastName, fullName, email, message, token, tenantId, tenantUrl) {
   
    // Using a try/catch because calls are formatted differently between Android and iOS
    try {
        console.log("mobilebridge.js -> Inside Bridge.pingAuthenticationSuccess try");
        alert("mobilebridge.js -> Inside Bridge.pingAuthenticationSuccess try");
      if (typeof NativeBridge.pingAuthenticationSuccess === 'function') {
        NativeBridge.pingAuthenticatedSuccess(authenticated, sessionCookie, impersonating, userType, firstName, lastName, fullName, email, message, token, tenantId, tenantUrl);
        console.log("mobilebridge.js -> Completing Bridge.pingAuthenticationSuccess try");
        alert("mobilebridge.js -> Completing Bridge.pingAuthenticationSuccess try");
      }
    }
    catch (err) {
      console.log("mobilebridge.js -> Inside Bridge.pingAuthenticationSuccess Error");
      alert("mobilebridge.js -> Inside Bridge.pingAuthenticationSuccess Error");
      try {
        var message = {
          "Authenticated": authenticated, "SessionCookie": sessionCookie, "Impersonating": impersonating, "UserType": userType, "FirstName": firstName,
          "LastName": lastName, "FullName": fullName, "Email": email, "Message": message, "Token": token, "TenantId": tenantId, "TenantUrl": tenantUrl
        };

        console.log("mobilebridge.js -> Inside Bridge.pingAuthenticationSuccess.postMessage try");
        alert("mobilebridge.js -> Inside Bridge.pingAuthenticationSuccess.postMessage try");

        window.webkit.messageHandlers.pingAuthenticatedSuccess.postMessage(message);
      }
      catch (err) {
        console.log("mobilebridge.js -> Inside Bridge.pingAuthenticationSuccess.postMessage Error");
        alert("mobilebridge.js -> Inside Bridge.pingAuthenticationSuccess.postMessage Error");
        console.log(NO_FUNCTION_FOUND);
      }
    }
  },
  pingAuthenticationFailure: function (errorCode, message) {
    // Using a try/catch because calls are formatted differently between Android and iOS
    try {
      if (typeof NativeBridge.pingAuthenticationSuccess === 'function') {
        NativeBridge.pingAuthenticationFailure(errorCode, message);
      }
    }
    catch (err) {
      try {
        var message = { "errorCode": errorCode, "message": message };
        window.webkit.messageHandlers.pingAuthenticationFailure.postMessage(message);
      }
      catch (err) {
        console.log(NO_FUNCTION_FOUND);
      }
    }
  }
};
